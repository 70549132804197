<template>
    <div class="display">
        {{ pastHistory }}
    </div>
</template>

<script>
import { CaseDisplayModel } from "@c/user-main/holography/case-display-model.js";
export default {
    props: {
        caseModel: Object
    },

    computed: {
        pastHistory() {
            if (this.caseModel) {
                return new CaseDisplayModel(this.caseModel).pastHistory()
            } else {
                return placeholderText("")
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.display {
    font-size: 16px;
    box-sizing: border-box;
    padding: 20px 41px 20px 41px;

}
</style>