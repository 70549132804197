<template>
    <div class="edit">
        <edit-selector title="心脑血管疾病史" :dataArray="heartDataArray" v-model="heartValue" style="width: 50%;"></edit-selector>
        <edit-selector title="内分泌系统疾病史" :dataArray="endocrineDataArray" v-model="endocrineValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="呼吸系统疾病史" :dataArray="breathDataArray" v-model="breathValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="肿瘤史" :dataArray="tumorDataArray" v-model="tumorValue" style="width: 50%;"></edit-selector>
        <edit-textarea title="肝肾异常史" v-model="kidneyDisease" :row="1" style="width: 100%;"></edit-textarea>
        <edit-textarea title="传染病史" v-model="infectionDisease" :row="1" style="width: 100%;"></edit-textarea>
        <edit-textarea title="外伤、手术史" v-model="surgery" :row="1" style="width: 100%;"></edit-textarea>
        <edit-textarea title="食物、药物过敏史" v-model="allergy" :row="1" style="width: 100%;"></edit-textarea>
    </div>
</template>

<script>
import EditSelector from "../component/pop-view-holography-case-edit-selector.vue";
import EditTextarea from "../component/pop-view-holography-case-edit-textarea.vue";

import { getKeyValueArray } from "@js/object-tool.js";
export default {
    components: {
        EditSelector,
        EditTextarea
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            heartValue: [],
            endocrineValue: [],
            breathValue: [],
            tumorValue: [],
            kidneyDisease: "",
            infectionDisease: "",
            surgery: "",
            allergy: ""
        }
    },

    computed: {
        heartDataArray() {
            return getKeyValueArray(HEART_DATA_ARRAY)
        },

        endocrineDataArray() {
            return getKeyValueArray(ENDOCRINE_DATA_ARRAY)
        },

        breathDataArray() {
            return getKeyValueArray(BREATH_DATA_ARRAY)
        },

        tumorDataArray() {
            return getKeyValueArray(TUMOR_DATA_ARRAY)
        }

    },

    mounted() {
        const antecedentHistory = this.caseModel?.antecedentHistory
        if (antecedentHistory) {
            this.heartValue = antecedentHistory.cardiovascular
            this.endocrineValue = antecedentHistory.endocrine
            this.breathValue = antecedentHistory.respiratory
            this.tumorValue = antecedentHistory.tumor
            this.kidneyDisease = antecedentHistory.liverKidneyAbnormalities
            this.infectionDisease = antecedentHistory.pestilence
            this.surgery = antecedentHistory.traumaSurgery
            this.allergy = antecedentHistory.foodDrugAllergies
        }
    }


}
</script>

<style lang="scss" scoped>
.edit {
    box-sizing: border-box;
    padding: 20px 41px 0px 9px;
    display: flex;
    flex-wrap: wrap;
}
</style>