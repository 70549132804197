<template>
    <div class="edit">
        <div class="edit-smoke">
            <edit-selector-single title="吸烟史" :dataArray="smokeHistoryArray" v-model="smokeHistoryValue"
                style="width: 33%;"></edit-selector-single>
            <edit-selector-single v-if="smokeHistoryValue == 1" title="烟龄" :dataArray="smokeAgeArray"
                v-model="smokeAgeValue" style="width: 33%;"></edit-selector-single>
            <edit-selector-single v-if="smokeHistoryValue == 1" title="每天数量" :dataArray="smokeNumberArray"
                v-model="smokeNumberValue" style="width: 34%;"></edit-selector-single>
        </div>
        <div class="edit-alcohol">
            <edit-selector-single title="饮酒史" :dataArray="alcoholHistoryArray" v-model="alcoholHistoryValue"
                style="width: 28%;"></edit-selector-single>
            <edit-selector-single v-if="alcoholHistoryValue == 1" title="酒龄" :dataArray="alcoholAgeArray"
                v-model="alcoholAgeValue" style="width: 26%;"></edit-selector-single>
            <edit-selector-single v-if="alcoholHistoryValue == 1" title="主要酒类" :dataArray="alcoholTypeArray"
                v-model="alcoholTypeValue" style="width: 30%;"></edit-selector-single>
            <edit-selector-single v-if="alcoholHistoryValue == 1" title="饮用量" :dataArray="alcoholQuantityArray"
                v-model="alcoholQuantityValue" style="width: 24%;"></edit-selector-single>
        </div>
        <div class="edit-drink">
            <edit-selector-single title="咖啡、浓茶" :dataArray="drinkArray" v-model="drinkValue"></edit-selector-single>
        </div>
        <edit-textarea title="精神科药物" v-model="drug"></edit-textarea>
    </div>
</template>

<script>
import EditSelectorSingle from "../component/pop-view-holography-case-edit-selector-single.vue";
import EditTextarea from "../component/pop-view-holography-case-edit-textarea.vue";
import { getKeyValueArray } from "@js/object-tool.js";
export default {
    components: {
        EditSelectorSingle,
        EditTextarea
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            smokeHistoryValue: undefined,
            smokeAgeValue: undefined,
            smokeNumberValue: undefined,
            alcoholHistoryValue: undefined,
            alcoholAgeValue: undefined,
            alcoholTypeValue: undefined,
            alcoholQuantityValue: undefined,
            drinkValue: undefined,
            drug: ""
        }
    },

    computed: {
        smokeHistoryArray() {
            return getKeyValueArray(SMOKE_HISTORY_ARRAY)
        },

        smokeAgeArray() {
            return getKeyValueArray(SMOKE_AGE_ARRAY)
        },

        smokeNumberArray() {
            return getKeyValueArray(SMOKE_NUMBER_ARRAY)
        },

        alcoholHistoryArray() {
            return getKeyValueArray(ALCOHOL_HISTORY_ARRAY)
        },

        alcoholAgeArray() {
            return getKeyValueArray(ALCOHOL_AGE_ARRAY)
        },

        alcoholTypeArray() {
            return getKeyValueArray(ALCOHOL_TYPE_ARRAY)
        },

        alcoholQuantityArray() {
            return getKeyValueArray(ALCOHOL_QUANTITY_ARRAY)
        },

        drinkArray() {
            return getKeyValueArray(DRINK_ARRAY)
        }

    },

    mounted() {
        const personalHistory = this.caseModel?.personalHistory
        if (personalHistory) {
            this.smokeHistoryValue = personalHistory.smokingHistory
        this.smokeAgeValue = personalHistory.smokingAge
        this.smokeNumberValue = personalHistory.numberPerDay
        this.alcoholHistoryValue = personalHistory.drinkingHistory
        this.alcoholAgeValue = personalHistory.drinkingAge
        this.alcoholTypeValue = personalHistory.mainLiquors
        this.alcoholQuantityValue = personalHistory.drinkingAmount
        this.drinkValue = personalHistory.coffeeAndStrongTea
        this.drug = personalHistory.psychiatricDrugs
        }
    },

    watch: {
        smokeHistoryValue(value) {
            if (value == SMOKE_HISTORY_ARRAY.无吸烟史) {
                this.smokeAgeValue = undefined
                this.smokeNumberValue = undefined
            }
        },

        alcoholHistoryValue(value) {
            if (value == ALCOHOL_HISTORY_ARRAY.无饮酒史) {
                this.alcoholAgeValue = undefined
                this.alcoholTypeValue = undefined
                this.alcoholQuantityValue = undefined
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.edit {
    box-sizing: border-box;
    padding: 20px 41px 0px 9px;


    &-smoke {
        display: flex;

        /deep/.pop-view-holography-case-edit-selector-title {
            margin-left: 32px;
        }

        :last-child {
            /deep/.pop-view-holography-case-edit-selector-content {
                margin-right: 0;
            }
        }
    }

    &-alcohol {
        display: flex;
        margin-left: 22px;

        /deep/.pop-view-holography-case-edit-selector-title {
            margin-left: 10px;
        }

        :last-child {
            /deep/.pop-view-holography-case-edit-selector-content {
                margin-right: 0;
            }
        }
    }

    &-drink {
        /deep/.pop-view-holography-case-edit-selector-content {
            margin-right: 0px;
        }
    }
}
</style>