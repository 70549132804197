<template>
    <div class="report-container">
        <button v-if="isEdit" class="upload upload-btn-position margin-t-10" type="button"
            @click.stop.prevent="onClickUpload">上传检验报告</button>
        <BaselineInfoCardReport class="margin-t-10" ref="cardReportRef" :isEdit="isEdit" :reportData="reportData"
            :user="user" @onReportDataChange="onReportDataChange"></BaselineInfoCardReport>
    </div>
</template>

<script>
import BaselineInfoCardReport from '@c/user-main/baseline/baseline-info-card/baseline-info-card-report.vue'

export default {
    components: {
        BaselineInfoCardReport
    },
    props: {
        isEdit: Boolean,
        reportData: Object,
        user: Object,
    },

    data() {
        return {

        }
    },
    methods: {
        onReportDataChange(obj) {
            this.$emit('onReportDataChange', obj)
        },

        onClickUpload() {
            this.$refs.cardReportRef?.show()
        },
    },
}
</script>
<style scoped lang='scss'>
.margin-t-10 {
    margin-top: 10px;
}

.report-container {
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.upload {
    width: 110px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    color: #2E6BE6;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #2E6BE6;
}

.upload-btn-position {
    margin-left: 20px;

}
</style>