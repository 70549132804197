<template>
    <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
    <display v-else :caseModel="caseModel"></display>
</template>

<script>
import Edit from "./edit.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },

    computed: {
        heartValue() {
            return this.isEditable ? this.$refs.edit.heartValue : this.caseModel?.antecedentHistory?.cardiovascular
        },
        endocrineValue() {
            return this.isEditable ? this.$refs.edit.endocrineValue : this.caseModel?.antecedentHistory?.endocrine
        },
        breathValue() {
            return this.isEditable ? this.$refs.edit.breathValue : this.caseModel?.antecedentHistory?.respiratory
        },
        tumorValue() {
            return this.isEditable ? this.$refs.edit.tumorValue : this.caseModel?.antecedentHistory?.tumor
        },
        kidneyDisease() {
            return this.isEditable ? this.$refs.edit.kidneyDisease : this.caseModel?.antecedentHistory?.liverKidneyAbnormalities
        },
        infectionDisease() {
            return this.isEditable ? this.$refs.edit.infectionDisease : this.caseModel?.antecedentHistory?.pestilence
        },
        surgery() {
            return this.isEditable ? this.$refs.edit.surgery : this.caseModel?.antecedentHistory?.traumaSurgery
        },
        allergy() {
            return this.isEditable ? this.$refs.edit.allergy : this.caseModel?.antecedentHistory?.foodDrugAllergies
        }
    }
}
</script>

