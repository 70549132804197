<template>
    <div class="display">
        <p class="display-item" v-for="item, index in parameterArray" :key="index">{{ item }}</p>
    </div>
</template>

<script>
import { CaseDisplayModel } from "@c/user-main/holography/case-display-model.js";
export default {
    props: {
        caseModel: Object
    },

    data() {
        return {
            parameterArray: []
        }
    },

    async created() {
        this.parameterArray = await this.composeParameterArray()
    },

    watch: {
        async caseModel() {
            this.parameterArray = await this.composeParameterArray()
        }
    },

    methods: {
        async composeParameterArray() {
            const model = new CaseDisplayModel(this.caseModel)
            const parameterStr = await model.parameter()
            if (parameterStr) {
                return parameterStr.split('，')
            } else {
                return [`工作模式：${placeholderText(null)}`]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.display {
    box-sizing: border-box;
    padding: 20px 41px 0px 41px;
    flex-wrap: wrap;
    display: flex;


    &-item {
        font-size: 16px;
        width: 50%;
        margin-bottom: 30px;

    }

}
</style>