<template>
    <pop-view>
        <div class="pop-view-holography-case-edit">
            <div class="pop-view-holography-case-edit-top">
                <div class="pop-view-holography-case-edit-top-left">
                    <button class="pop-view-holography-case-edit-top-button-blue" @click="onPublishClick">发布</button>
                    <button class="pop-view-holography-case-edit-top-button-gray" @click="onSaveClick">暂存</button>
                </div>
                <button class="pop-view-holography-case-edit-top-button-gray" @click="onCancelClick">取消</button>
            </div>
            <el-collapse class="pop-view-holography-case-edit-collape" v-model="activeNames">
                <el-collapse-item name="主诉">
                    <template slot="title">
                        <collapse-header title="主诉" :isOpen="activeNames.includes('主诉')"
                            v-model="isChielfComplaintEditable"></collapse-header>
                    </template>
                    <chief-complaint ref="chiefComplaint" :caseModel="caseModel"
                        :isEditable="isChielfComplaintEditable"></chief-complaint>
                </el-collapse-item>
                <el-collapse-item name="现病史">
                    <template slot="title">
                        <collapse-header title="现病史" :isOpen="activeNames.includes('现病史')"
                            v-model="isCurrentIllnessHistoryEditable"></collapse-header>
                    </template>
                    <current-illness-history ref="currentIllnessHistory" :caseModel="caseModel"
                        :isEditable="isCurrentIllnessHistoryEditable"></current-illness-history>
                </el-collapse-item>
                <el-collapse-item name="既往史">
                    <template slot="title">
                        <collapse-header title="既往史" :isOpen="activeNames.includes('既往史')"
                            v-model="isPastIllnessHistoryEditable"></collapse-header>
                    </template>
                    <past-illness-history ref="pastIllnessHistory" :caseModel="caseModel"
                        :isEditable="isPastIllnessHistoryEditable"></past-illness-history>
                </el-collapse-item>
                <el-collapse-item name="个人史">
                    <template slot="title">
                        <collapse-header title="个人史" :isOpen="activeNames.includes('个人史')"
                            v-model="isPersonalIllnessHistoryEditable"></collapse-header>
                    </template>
                    <persional-illness-history ref="personalIllnessHistory" :caseModel="caseModel"
                        :isEditable="isPersonalIllnessHistoryEditable"></persional-illness-history>
                </el-collapse-item>
                <el-collapse-item name="体格检查">
                    <template slot="title">
                        <collapse-header title="体格检查" :isOpen="activeNames.includes('体格检查')"
                            v-model="isPhysicalExaminationEditable"></collapse-header>
                    </template>
                    <physical-examination ref="physicalExamination" :caseModel="caseModel"
                        :isEditable="isPhysicalExaminationEditable"></physical-examination>
                </el-collapse-item>
                <el-collapse-item name="诊断结果">
                    <template slot="title">
                        <collapse-header title="诊断结果" :isOpen="activeNames.includes('诊断结果')"
                            v-model="isResultEditable"></collapse-header>
                    </template>
                    <result ref="result" :caseModel="caseModel" :isEditable="isResultEditable"></result>
                </el-collapse-item>
                <el-collapse-item name="治疗参数">
                    <template slot="title">
                        <collapse-header title="治疗参数" :isOpen="activeNames.includes('治疗参数')"
                            v-model="isParameterEditable"></collapse-header>
                    </template>
                    <parameter ref="parameter" :caseModel="caseModel" :isEditable="isParameterEditable"></parameter>
                </el-collapse-item>
                <el-collapse-item name="检验报告">
                    <template slot="title">
                        <collapse-header title="检验报告" :isOpen="activeNames.includes('检验报告')"
                            v-model="isUploaderEditable"></collapse-header>
                    </template>
                    <ExaminationReport ref="examinationReportRef" :isEdit="isUploaderEditable" :reportData="caseModel"
                        :user="patientDetailModel" @onReportDataChange="onReportDataChange"></ExaminationReport>
                </el-collapse-item>
            </el-collapse>
        </div>
    </pop-view>
</template>
  
<script>
import PopView from "@c/common/pop-view/pop-view.vue";
import { Collapse, CollapseItem } from "element-ui";
import ChiefComplaint from "./chief-complaint/index.vue";
import CurrentIllnessHistory from "./current-illness-history/index.vue";
import PastIllnessHistory from "./past-illness-history/index.vue";
import PersionalIllnessHistory from "./personal-illness-history/index.vue";
import PhysicalExamination from "./physical-examination/index.vue";
import Result from "./result/index.vue";
import Parameter from "./parameter/index.vue";
import CollapseHeader from "./collapse-header.vue";
import ExaminationReport from './examination-report/index.vue'

export default {
    components: {
        PopView,
        elCollapse: Collapse,
        elCollapseItem: CollapseItem,
        CollapseHeader,
        ChiefComplaint,
        CurrentIllnessHistory,
        PastIllnessHistory,
        PersionalIllnessHistory,
        PhysicalExamination,
        Result,
        Parameter,
        ExaminationReport,
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        isShow: Boolean,
        patientDetailModel: Object,
        initialCaseModel: Object
    },

    data() {
        return {
            caseModel: undefined,
            activeNames: [],
            //isEditable
            isChielfComplaintEditable: false,
            isCurrentIllnessHistoryEditable: false,
            isPastIllnessHistoryEditable: false,
            isPersonalIllnessHistoryEditable: false,
            isPhysicalExaminationEditable: false,
            isResultEditable: false,
            isParameterEditable: false,
            isUploaderEditable: false,
            examinationReportData: {},
        }
    },

    //v-if用（编辑）
    mounted() {
        if (this.initialCaseModel) {
            this.caseModel = { ...this.initialCaseModel }
        }
    },

    //v-show用（新增）
    watch: {
        isShow(value) {
            if (!value) return
            if (!this.initialCaseModel) {
                if (!this.caseModel) {
                    this.getPreviousCaseModel()
                }
            }
        },

        caseModel(value) {
            //展开状态
            this.activeNames = []
            if (!isEmptyObject(value?.complaints)) { this.activeNames.push("主诉") }
            if (!isEmptyObject(value?.medicalHistory)) { this.activeNames.push("现病史") }
            if (!isEmptyObject(value?.antecedentHistory)) { this.activeNames.push("既往史") }
            if (!isEmptyObject(value?.personalHistory)) { this.activeNames.push("个人史") }
            if (!isEmptyObject(value?.characterization)) { this.activeNames.push("体格检查") }
            if (!isEmptyObject(value?.diagnosis)) { this.activeNames.push("诊断结果") }
            if (!isEmptyObject(value?.parameter)) { this.activeNames.push("治疗参数") }
            if (!isEmptyObject(value?.inspectionReportFile) || !isEmptyObject(value?.sleepReport) || !isEmptyObject(value?.sleepReportFile)) { this.activeNames.push("检验报告") }
            //编辑状态
            this.isChielfComplaintEditable = false
            this.isCurrentIllnessHistoryEditable = false
            this.isPastIllnessHistoryEditable = false
            this.isPersonalIllnessHistoryEditable = false
            this.isPhysicalExaminationEditable = false
            this.isResultEditable = false
            this.isParameterEditable = false
            this.isUploaderEditable = false

            function isEmptyObject(object) {
                if (!object) {
                    return true
                } else {
                    return !Object.values(object).some(i => !!i)
                }
            }
        },

        isChielfComplaintEditable(value) {
            this.modifyActiveNames("主诉", value)
        },
        isCurrentIllnessHistoryEditable(value) {
            this.modifyActiveNames("现病史", value)
        },
        isPastIllnessHistoryEditable(value) {
            this.modifyActiveNames("既往史", value)
        },
        isPersonalIllnessHistoryEditable(value) {
            this.modifyActiveNames("个人史", value)
        },
        isPhysicalExaminationEditable(value) {
            this.modifyActiveNames("体格检查", value)
        },
        isResultEditable(value) {
            this.modifyActiveNames("诊断结果", value)
        },
        isParameterEditable(value) {
            this.modifyActiveNames("治疗参数", value)
        },
        isUploaderEditable(value) {
            this.modifyActiveNames("检验报告", value)
        }
    },

    methods: {
        onCancelClick() {
            this.$emit('onIsShowChanged', false)
        },

        onSaveClick() {
            const isPublish = false
            this.submit(isPublish)
        },

        onPublishClick() {
            const isPublish = true
            this.submit(isPublish)
        },

        onReportDataChange(obj) {
            this.examinationReportData = { ...obj }
        },

        async submit(isPublish) {
            try {
                //主诉
                const chiefComplaintRef = this.$refs.chiefComplaint
                const complaints = JSON.stringify({
                    symptom: chiefComplaintRef.symptom,
                    diseaseDuration: chiefComplaintRef.course
                })
                //现病史
                const currentIllnessHistoryRef = this.$refs.currentIllnessHistory
                const medicalHistory = JSON.stringify({
                    sleepProblems: currentIllnessHistoryRef.sleepValue,
                    dietaryProblems: currentIllnessHistoryRef.eatValue,
                    downcast: currentIllnessHistoryRef.emotionLowValue,
                    anxiety: currentIllnessHistoryRef.emotionAnxiousValue,
                    emotionalHigh: currentIllnessHistoryRef.emotionHighValue,
                    otherDepressive: currentIllnessHistoryRef.depressValue,
                    otherAnxiety: currentIllnessHistoryRef.anxiousValue,
                    otherManic: currentIllnessHistoryRef.crazyValue,
                    social: currentIllnessHistoryRef.socialValue,
                    riskAssessment: currentIllnessHistoryRef.riskValue,
                })
                //既往史
                const pastIllnessHistoryRef = this.$refs.pastIllnessHistory
                const antecedentHistory = JSON.stringify({
                    cardiovascular: pastIllnessHistoryRef.heartValue,
                    endocrine: pastIllnessHistoryRef.endocrineValue,
                    respiratory: pastIllnessHistoryRef.breathValue,
                    tumor: pastIllnessHistoryRef.tumorValue,
                    liverKidneyAbnormalities: pastIllnessHistoryRef.kidneyDisease,
                    pestilence: pastIllnessHistoryRef.infectionDisease,
                    traumaSurgery: pastIllnessHistoryRef.surgery,
                    foodDrugAllergies: pastIllnessHistoryRef.allergy,
                })
                //个人史
                const personalIllnessHistoryRef = this.$refs.personalIllnessHistory
                const personalHistory = JSON.stringify({
                    smokingHistory: personalIllnessHistoryRef.smokeHistoryValue,
                    smokingAge: personalIllnessHistoryRef.smokeAgeValue,
                    numberPerDay: personalIllnessHistoryRef.smokeNumberValue,
                    drinkingHistory: personalIllnessHistoryRef.alcoholHistoryValue,
                    drinkingAge: personalIllnessHistoryRef.alcoholAgeValue,
                    mainLiquors: personalIllnessHistoryRef.alcoholTypeValue,
                    drinkingAmount: personalIllnessHistoryRef.alcoholQuantityValue,
                    coffeeAndStrongTea: personalIllnessHistoryRef.drinkValue,
                    psychiatricDrugs: personalIllnessHistoryRef.drug
                })
                //体格检查
                const physicalExaminationRef = this.$refs.physicalExamination
                const characterization = JSON.stringify({
                    height: physicalExaminationRef.height,
                    weight: physicalExaminationRef.weight,
                    neckband: physicalExaminationRef.neck,
                    bloodPressure: physicalExaminationRef.bloodPressure,
                    heartRate: physicalExaminationRef.heartRate
                })
                //诊断结果
                const resultRef = this.$refs.result
                const diagnosis = resultRef.result
                //治疗参数
                const parameterRef = this.$refs.parameter
                const parameter = parameterRef.jsonStr ?? null
                const maskType = parameterRef.maskTypeId
                //体检报告
                const inspectionReportFile = this.examinationReportData?.inspectionReportFile ? JSON.stringify(this.examinationReportData?.inspectionReportFile) : null
                const sleepReport = this.examinationReportData?.sleepReport ? JSON.stringify(this.examinationReportData?.sleepReport) : null
                const sleepReportFile = this.examinationReportData?.sleepReportFile ? JSON.stringify(this.examinationReportData?.sleepReportFile) : null

                //param
                const param = {
                    "id": this.initialCaseModel ? this.caseModel?.id : null,
                    "patientId": this.patientDetailModel.model.id,
                    "hospitalId": this.patientDetailModel.model.hospitalId,
                    "status": isPublish,
                    "parameter": parameter,
                    "complaints": complaints,
                    "medicalHistory": medicalHistory,
                    "antecedentHistory": antecedentHistory,
                    "personalHistory": personalHistory,
                    "characterization": characterization,
                    "diagnosis": diagnosis,
                    "maskTypeId": maskType,
                    "inspectionReportFile": inspectionReportFile,
                    "sleepReport": sleepReport,
                    "sleepReportFile": sleepReportFile,
                }
                await this.$api.addOrEditCase(param);
                this.caseModel = undefined
                this.examinationReportData  = undefined
                this.$toast.showGreen(this.initialCaseModel ? "编辑成功" : "新建成功")
                this.$emit('onEditSucceed')
                this.$emit('onIsShowChanged', false)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async getPreviousCaseModel() {
            try {
                const param = {
                    patientId: this.patientDetailModel.model.id,
                    status: true
                }
                const previousCaseModel = await this.$api.getPreviousCaseDetail(param)
                //主诉、诊断结果、检验报告不用读取
                previousCaseModel.complaints = null
                previousCaseModel.diagnosis = null
                previousCaseModel.sleepReport = null
                previousCaseModel.sleepReportFile = null
                previousCaseModel.inspectionReportFile = null
                if (previousCaseModel) {
                    this.caseModel = previousCaseModel
                }
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        modifyActiveNames(title, isEditable) {
            if (isEditable && !this.activeNames.includes(title)) {
                this.activeNames.push(title)
            }
        },

        onReportDataChange(obj) {
            this.examinationReportData = { ...obj }
        },
    },
};
</script>
  
<style lang="scss" scoped>
.pop-view-holography-case-edit {
    width: 820px;

    &-top {
        width: 100%;
        height: 58px;
        border-bottom: 1px solid #E8E8E8;
        box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-left {
            display: flex;
        }

        &-button-blue {
            color: white;
            background-color: #2E6BE6;
            font-size: 14px;
            border-radius: 6px;
            width: 63px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
        }

        &-button-gray {
            color: #2E6BE6;
            background-color: white;
            font-size: 14px;
            border-radius: 6px;
            border: 1px solid #2E6BE6;
            width: 63px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-collape {
        max-height: 800px;
        overflow: hidden;
        overflow-y: auto;

        &-title {
            font-size: 18px;
            font-weight: bold;
            margin-left: 41px;
        }

        /deep/.el-collapse-item__arrow {
            display: none;
        }

        /deep/.el-collapse-item__header {
            border-bottom: 1px solid #e8e8e8;
        }

        /deep/.el-collapse-item__content {
            padding-bottom: 0px;
        }
    }

    &-content {
        box-sizing: border-box;
        padding: 20px 44px 34px 24px;
    }

    &-bottom {
        border-top: 1px solid rgba($color: black, $alpha: 0.09);
        width: 100%;
        height: 55px;
        box-sizing: border-box;
        padding-right: 42px;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;

        &-confirm {
            color: white;
            background-color: #2e6be6;
            font-size: 14px;
            border-radius: 3px;
            width: 65px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-cancel {
            color: rgba($color: #000000, $alpha: 0.65);
            background-color: white;
            font-size: 14px;
            border-radius: 3px;
            border: 1px solid #e8e8e8;
            width: 65px;
            height: 32px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>