<template>
    <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
    <display v-else :caseModel="caseModel"></display>
</template>

<script>
import Edit from "./edit.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },

    computed: {
        smokeHistoryValue() {
            return this.isEditable ? this.$refs.edit.smokeHistoryValue : this.caseModel?.personalHistory?.smokingHistory
        },
        smokeAgeValue() {
            return this.isEditable ? this.$refs.edit.smokeAgeValue : this.caseModel?.personalHistory?.smokingAge
        },
        smokeNumberValue() {
            return this.isEditable ? this.$refs.edit.smokeNumberValue : this.caseModel?.personalHistory?.numberPerDay
        },
        alcoholHistoryValue() {
            return this.isEditable ? this.$refs.edit.alcoholHistoryValue : this.caseModel?.personalHistory?.drinkingHistory
        },
        alcoholAgeValue() {
            return this.isEditable ? this.$refs.edit.alcoholAgeValue : this.caseModel?.personalHistory?.drinkingAge
        },
        alcoholTypeValue() {
            return this.isEditable ? this.$refs.edit.alcoholTypeValue : this.caseModel?.personalHistory?.mainLiquors
        },
        alcoholQuantityValue() {
            return this.isEditable ? this.$refs.edit.alcoholQuantityValue : this.caseModel?.personalHistory?.drinkingAmount
        },
        drinkValue() {
            return this.isEditable ? this.$refs.edit.drinkValue : this.caseModel?.personalHistory?.coffeeAndStrongTea
        },
        drug() {
            return this.isEditable ? this.$refs.edit.drug : this.caseModel?.personalHistory?.psychiatricDrugs
        },
    }
}
</script>