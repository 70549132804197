<template>
    <div class="parameter">
        <div class="parameter-header">
            <record-selector class="parameter-header-selector" title="工作模式" v-model="modeValue"
                :dataArray="modeArray"></record-selector>
        </div>
        <component ref="componentRef" :is="currentComponent" :initParamModel="paramModel">
        </component>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import { modeArray } from "@js/parameter-option-data-source.js";
export default {
    components: {
        RecordSelector,
        Apap: () => import("./apap.vue"),
        Cpap: () => import("./cpap.vue"),
        BpapS: () => import("./bpap-s.vue"),
        BpapT: () => import("./bpap-t.vue"),
        BpapSt: () => import("./bpap-st.vue"),
        AutoBpapS: () => import("./auto-bpap-s.vue"),
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            modeArray: modeArray,
            modeValue: undefined,
            paramModel: {},
            componentRef: undefined
        }
    },

    computed: {
        currentComponent() {
            switch (this.modeValue) {
                case 0:
                    return "cpap"
                case 1:
                    return "apap"
                case 2:
                    return "bpap-s"
                case 3:
                    return "auto-bpap-s"
                case 4:
                    return "bpap-t"
                case 5:
                    return "bpap-st"
                default:
                    break;
            }
        }
    },

    async mounted(){
        const parameter = await this.caseModel?.parameter
        this.modeValue = parseInt(parameter?.cureMode)
        this.paramModel = parameter ?? {}
    },

    updated() {
        this.componentRef = this.$refs.componentRef
    },

    watch: {
        paramModel(value) {
            this.modeValue = parseInt(value?.cureMode)
        }
    }
}
</script>

<style lang="scss" scoped>
.parameter {
    box-sizing: border-box;
    padding: 20px 41px;

    &-header {
        width: 50%;
        box-sizing: border-box;
        padding-right: 12px;
    }


    display: flex;
    flex-direction: column;

    &-column {
        width: 50%;

        /deep/.record-box-top {
            .record-input-key {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

}
</style>