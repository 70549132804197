<template>
    <div class="collapse-header">
        <div class="collapse-header-left">
            <div>
                <img v-if="isOpen" class="collapse-header-left-arrow" :src="arrowUp">
                <img v-else class="collapse-header-left-arrow" :src="arrowDown">
            </div>
            <p class="collapse-header-left-title">{{ title }}</p>
        </div>
        <button v-if="!isEdit" class="collapse-header-edit" @click.stop="onEdit">编辑</button>
    </div>
</template>

<script>
export default {
    model: {
        prop: "isEdit",
        event: "onIsEditChanged"
    },

    props: {
        title: String,
        isOpen: Boolean,
        isEdit: Boolean
    },

    data() {
        return {
            arrowUp: require('@imgs/holography-case-edit-alert-arrow-up.png'),
            arrowDown: require('@imgs/holography-case-edit-alert-arrow-down.png')

        }
    },

    methods: {
        onEdit() {
            this.$emit("onIsEditChanged", true)
        }
    }
}
</script>

<style lang="scss" scoped>
.collapse-header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
        display: flex;
        align-items: center;

        &-arrow {
            width: 14px;
            height: 10px;
            margin-left: 41px;
        }

        &-title {
            font-size: 17px;
            font-weight: bold;
            margin-left: 10px;
        }
    }

    &-edit {
        color: #2E6BE6;
        border: 1px solid #2E6BE6;
        border-radius: 6px;
        font-size: 14px;
        width: 63px;
        height: 28px;
        margin-right: 40px;
    }
}
</style>