<template>
    <div class="edit">
        <edit-textarea v-model="result" style="width: 100%;"></edit-textarea>
    </div>
</template>

<script>
import EditTextarea from "../component/pop-view-holography-case-edit-textarea.vue";
export default {
    components: {
        EditTextarea
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            result: ""
        }
    },

    mounted(){
        this.result = this.caseModel?.diagnosis
    }
}
</script>

<style lang="scss" scoped>
.edit {
    box-sizing: border-box;
    padding: 20px 41px 0px 9px;
    display: flex;
    flex-wrap: wrap;
}
</style>