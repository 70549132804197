<template>
    <div class="chief-complaint">
        <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
        <display v-else  :caseModel="caseModel"></display>
    </div>
</template>

<script>
import Edit from "./edit.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },

    computed:{
        symptom(){
            return this.isEditable ? this.$refs.edit.symptom : this.caseModel?.complaints?.symptom
        },

        course(){
            return this.isEditable ? this.$refs.edit.course : this.caseModel?.complaints?.diseaseDuration
        }
    }
}
</script>
