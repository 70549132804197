<template>
    <div class="chief-complaint">
        <edit-textarea title="症状" v-model="symptom" style="width: 100%;"></edit-textarea>
        <edit-textarea title="病程" v-model="course" style="width: 100%;"></edit-textarea>
    </div>
</template>

<script>
import EditTextarea from "../component/pop-view-holography-case-edit-textarea.vue";
export default {
    components: {
        EditTextarea
    },

    props: {
        caseModel: Object
    },

    data() {
        return {
            symptom: "",
            course: ""
        }
    },

    mounted() {
        this.symptom = this.caseModel?.complaints?.symptom
        this.course = this.caseModel?.complaints?.diseaseDuration
    },

    watch: {
        caseModel(value) {
            this.symptom = value.complaints?.symptom
            this.course = value.complaints?.diseaseDuration
        }
    }
}
</script>

<style lang="scss" scoped>
.chief-complaint {
    box-sizing: border-box;
    padding: 20px 41px 0px 9px;
    display: flex;
    flex-wrap: wrap;
}
</style>