<template>
    <div class="display">
        <div class="display-item">
            <p class="display-item-title">症状</p>
            ：
            <p class="display-item-content">{{ symptom }}</p>
        </div>
        <div class="display-item">
            <p class="display-item-title">病程</p>
            ：
            <p class="display-item-content">{{ course }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseModel: Object
    },

    computed: {
        symptom() {
            return placeholderText(this.caseModel?.complaints?.symptom)
        },
        course() {
            return placeholderText(this.caseModel?.complaints?.diseaseDuration)
        }
    }

}
</script>

<style lang="scss" scoped>
.display {
    font-size: 16px;
    box-sizing: border-box;
    padding: 20px 41px 0px 41px;

    &-item {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        display: flex;

        &-title {
            line-height: 22px;
        }

        &-content {
            flex: 1;
        }
    }
}
</style>