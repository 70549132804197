<template>
    <div class="pop-view-holography-case-edit-input">
        <p class="pop-view-holography-case-edit-input-title">
            <span v-if="isMust" class="pop-view-holography-case-edit-input-title-is-must">*</span>
            {{ title }}:
        </p>
        <el-input class="pop-view-holography-case-edit-input-content" v-model="tempValue" placeholder="请输入"></el-input>
        <p v-if="title" class="pop-view-holography-case-edit-input-unit">{{ unit }}</p>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        elInput: Input
    },

    model: {
        prop: "value",
        event: "onValueChanged"
    },

    props: {
        isMust: {
            type: Boolean,
            required: false,
            defalult: false
        },
        title: {
            type: String,
            required: false
        },
        value: String,
        unit: String
    },

    data() {
        return {
            tempValue: ""
        }
    },

    mounted() {
        this.tempValue = this.value
    },

    watch: {
        value(value) {
            this.tempValue = value
        },

        tempValue(value) {
            this.$emit("onValueChanged", value)
        }
    }

}
</script>

<style lang="scss" scoped>
.pop-view-holography-case-edit-input {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-title {
        &-is-must {
            color: #FC0000;
        }
    }

    &-content {
        font-size: 16px;
        flex: 1;
        margin-right: 10px;
        margin-left: 10px;
    }
}
</style>