<template>
    <div class="edit">
        <edit-selector title="睡眠问题" :dataArray="sleepDataArray" v-model="sleepValue" style="width: 50%;"></edit-selector>
        <edit-selector title="饮食问题" :dataArray="eatDataArray" v-model="eatValue" style="width: 50%;"></edit-selector>
        <edit-selector title="情绪低落" :dataArray="emotionLowDataArray" v-model="emotionLowValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="情绪焦虑" :dataArray="emotionTenseDataArray" v-model="emotionAnxiousValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="情绪高涨" :dataArray="emotionHighDataArray" v-model="emotionHighValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="其他抑郁症状" :dataArray="depressDataArray" v-model="depressValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="其他焦虑症状" :dataArray="anxiousDataArray" v-model="anxiousValue"
            style="width: 50%;"></edit-selector>
        <edit-selector title="其他躁狂症状" :dataArray="crasyDataArray" v-model="crazyValue" style="width: 50%;"></edit-selector>
        <edit-selector title="社会功能" :dataArray="socialDataArray" v-model="socialValue" style="width: 50%;"></edit-selector>
        <edit-selector title="风险评估" :dataArray="riskDataArray" v-model="riskValue" style="width: 50%;"></edit-selector>

    </div>
</template>

<script>
import EditSelector from "../component/pop-view-holography-case-edit-selector.vue";
import { getKeyValueArray } from "@js/object-tool.js";
export default {
    components: {
        EditSelector
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            sleepValue: [],
            eatValue: [],
            emotionLowValue: [],
            emotionAnxiousValue: [],
            emotionHighValue: [],
            depressValue: [],
            anxiousValue: [],
            crazyValue: [],
            socialValue: [],
            riskValue: [],
        }
    },

    computed: {
        sleepDataArray() {
            return getKeyValueArray(SLEEP_DATA_ARRAY)
        },

        eatDataArray() {
            return getKeyValueArray(EAT_DATA_ARRAY)
        },

        emotionLowDataArray() {
            return getKeyValueArray(EMOTION_LOW_DATA_ARRAY)
        },

        emotionTenseDataArray() {
            return getKeyValueArray(EMOTION_ANXIOUS_DATA_ARRAY)
        },

        emotionHighDataArray() {
            return getKeyValueArray(EMOTION_HIGH_DATA_ARRAY)
        },

        depressDataArray() {
            return getKeyValueArray(DEPRESS_DATA_ARRAY)
        },

        anxiousDataArray() {
            return getKeyValueArray(ANXIOUS_DATA_ARRAY)
        },

        crasyDataArray() {
            return getKeyValueArray(CRAZY_DATA_ARRAY)
        },

        socialDataArray() {
            return getKeyValueArray(SOCIAL_DATA_ARRAY)
        },

        riskDataArray() {
            return getKeyValueArray(RISK_DATA_ARRAY)
        }

    },

    mounted() {
        const medicalHistory = this.caseModel?.medicalHistory
        if (medicalHistory) {
            this.sleepValue = medicalHistory.sleepProblems
            this.eatValue = medicalHistory.dietaryProblems
            this.emotionLowValue = medicalHistory.downcast
            this.emotionAnxiousValue = medicalHistory.anxiety
            this.emotionHighValue = medicalHistory.emotionalHigh
            this.depressValue = medicalHistory.dietaryProblems
            this.anxiousValue = medicalHistory.otherAnxiety
            this.crazyValue = medicalHistory.otherManic
            this.socialValue = medicalHistory.social
            this.riskValue = medicalHistory.riskAssessment
        }
    }
}
</script>

<style lang="scss" scoped>
.edit {
    box-sizing: border-box;
    padding: 20px 41px 0px 9px;
    display: flex;
    flex-wrap: wrap;
}
</style>