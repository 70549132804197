<template>
    <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
    <display v-else :caseModel="caseModel"></display>
</template>

<script>
import Edit from "./edit/index.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },
    
    computed:{
        jsonStr(){
            return this.isEditable ? this.$refs.edit.componentRef?.jsonStr : JSON.stringify(this.caseModel?.parameter ?? undefined)
        },

        maskTypeId(){
            return this.isEditable ? this.$refs.edit.componentRef?.bottomRef?.maskType : JSON.stringify(this.caseModel?.maskTypeId ?? undefined) 
        }
    }
}
</script>

