<template>
    <div class="pop-view-holography-case-edit-selector">
        <p class="pop-view-holography-case-edit-selector-title">{{ title }}:</p>
        <el-select class="pop-view-holography-case-edit-selector-content" multiple collapse-tags v-model="tempSelectedValues"
            :popper-append-to-body="false" placeholder="请选择">
            <el-option v-for="(item, index) in dataArray" :key="index" :label="item.title" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {
    components: {
        elSelect: Select,
        elOption: Option,
    },

    model: {
        prop: "selectedValues",
        event: "onSelectedValuesChanged"
    },

    props: {
        title: String,
        dataArray: Array,
        selectedValues: Array
    },

    data() {
        return {
            tempSelectedValues: []
        }
    },

    mounted() {
        this.tempSelectedValues = this.selectedValues
    },

    watch: {
        selectedValues(value){
            this.tempSelectedValues = value
        },

        tempSelectedValues(value) {
            this.$emit("onSelectedValuesChanged", value)
        }
    }

}
</script>

<style lang="scss" scoped>
.pop-view-holography-case-edit-selector {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-title {
        font-size: 16px;
        margin-right: 10px;
        margin-left: 32px;
    }

    &-content {
        flex: 1;

        /deep/.el-input--suffix .el-input__inner {
            font-size: 16px;
        }

        /deep/.el-select-dropdown__item {
            font-size: 16px;
        }
    }
}
</style>