<template>
    <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
    <display v-else :caseModel="caseModel"></display>
</template>

<script>
import Edit from "./edit.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },

    computed: {
        height() {
            return this.isEditable ? this.$refs.edit.height : this.caseModel?.characterization?.height
        },
        weight() {
            return this.isEditable ? this.$refs.edit.weight : this.caseModel?.characterization?.weight
        },
        neck() {
            return this.isEditable ? this.$refs.edit.neck : this.caseModel?.characterization?.neckband
        },
        bloodPressure() {
            return this.isEditable ? this.$refs.edit.bloodPressure : this.caseModel?.characterization?.bloodPressure
        },
        heartRate() {
            return this.isEditable ? this.$refs.edit.heartRate : this.caseModel?.characterization?.heartRate
        }
    }
}
</script>