<template>
    <div class="edit">
        <edit-input title="身高" v-model="height" unit="cm" style="width: 36%;"></edit-input>
        <edit-input title="体重" v-model="weight" unit="kg" style="width: 36%;"></edit-input>
        <edit-input title="颈围" v-model="neck" unit="cm" style="width: 28%;"></edit-input>
        <edit-input title="血压" v-model="bloodPressure" unit="mmHg" style="width: 36%;"></edit-input>
        <edit-input title="心率" v-model="heartRate" unit="次/分" style="width: 36%;"></edit-input>

    </div>
</template>

<script>
import EditInput from "../component/pop-view-holography-case-edit-input.vue";
export default {
    components: {
        EditInput
    },

    props: {
        caseModel: {
            type: Object,
            required: false,
        }
    },

    data() {
        return {
            height: "",
            weight: "",
            neck: "",
            bloodPressure: "",
            heartRate: ""
        }
    },

    mounted() {
        const characterization = this.caseModel?.characterization
        if (characterization) {
            this.height = characterization.height
            this.weight = characterization.weight
            this.neck = characterization.neckband
            this.bloodPressure = characterization.bloodPressure
            this.heartRate = characterization.heartRate
        }
    }
}
</script>

<style lang="scss" scoped>
.edit {
    box-sizing: border-box;
    padding: 20px 41px 0px 41px;
    display: flex;
    flex-wrap: wrap;

    /deep/.pop-view-holography-case-edit-input-content {
        flex: none;
        width: 110px;

    }
}
</style>