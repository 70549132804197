<template>
    <div class="current-illness-history">
        <edit v-if="isEditable" ref="edit" :caseModel="caseModel"></edit>
        <display v-else :caseModel="caseModel"></display>
    </div>
</template>

<script>
import Edit from "./edit.vue";
import Display from "./display.vue";
export default {
    components: {
        Edit,
        Display
    },

    props: {
        isEditable: Boolean,
        caseModel: Object
    },

    computed: {
        sleepValue() {
            return this.isEditable ? this.$refs.edit.sleepValue : this.caseModel?.medicalHistory?.sleepProblems
        },
        eatValue() {
            return this.isEditable ? this.$refs.edit.eatValue : this.caseModel?.medicalHistory?.dietaryProblems
        },
        emotionLowValue() {
            return this.isEditable ? this.$refs.edit.emotionLowValue : this.caseModel?.medicalHistory?.downcast
        },
        emotionAnxiousValue() {
            return this.isEditable ? this.$refs.edit.emotionAnxiousValue : this.caseModel?.medicalHistory?.anxiety
        },
        emotionHighValue() {
            return this.isEditable ? this.$refs.edit.emotionHighValue : this.caseModel?.medicalHistory?.emotionalHigh
        },
        depressValue() {
            return this.isEditable ? this.$refs.edit.depressValue : this.caseModel?.medicalHistory?.otherDepressive
        },
        anxiousValue() {
            return this.isEditable ? this.$refs.edit.anxiousValue : this.caseModel?.medicalHistory?.otherAnxiety
        },
        crazyValue() {
            return this.isEditable ? this.$refs.edit.crazyValue : this.caseModel?.medicalHistory?.otherManic
        },
        socialValue() {
            return this.isEditable ? this.$refs.edit.socialValue : this.caseModel?.medicalHistory?.social
        },
        riskValue() {
            return this.isEditable ? this.$refs.edit.riskValue : this.caseModel?.medicalHistory?.riskAssessment
        }
    }


}
</script>
