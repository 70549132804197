<template>
    <div class="pop-view-holography-case-edit-textarea">
        <p v-if="title" class="pop-view-holography-case-edit-textarea-title">{{ title }}:</p>
        <el-input class="pop-view-holography-case-edit-textarea-content" v-model="tempValue" placeholder="请输入" type="textarea"
            :rows="row" clearable></el-input>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        elInput: Input
    },

    model: {
        prop: "value",
        event: "onValueChanged"
    },

    props: {
        title: {
            type: String,
            required: false
        },
        value: String,
        row: {
            type: Number,
            defalut: 2,
            required: false
        }
    },

    data() {
        return {
            tempValue: ""
        }
    },

    mounted() {
        this.tempValue = this.value
    },

    watch: {
        value(value){
            this.tempValue = value
        },

        tempValue(value) {
            this.$emit("onValueChanged", value)
        }
    }

}
</script>

<style lang="scss" scoped>
.pop-view-holography-case-edit-textarea {
    display: flex;
    margin-bottom: 20px;
    margin-left: 32px;

    &-title {
        font-size: 16px;
        line-height: 22px;
        margin-right: 10px;
    }

    &-content {
        flex: 1;
        font-size: 16px;
    }
}
</style>