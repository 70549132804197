<template>
    <div class="pop-view-holography-case-edit-selector">
        <p class="pop-view-holography-case-edit-selector-title">{{ title }}:</p>
        <el-select class="pop-view-holography-case-edit-selector-content" v-model="tempSelectedValue" clearable
            :popper-append-to-body="false" placeholder="请选择">
            <el-option v-for="(item, index) in dataArray" :key="index" :label="item.title" :value="item.value">
            </el-option>
        </el-select>
        <p v-if="unit" class="pop-view-holography-case-edit-selector-unit">{{ unit }}</p>
    </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {
    components: {
        elSelect: Select,
        elOption: Option,
    },

    model: {
        prop: "selectedValue",
        event: "onSelectedValueChanged"
    },

    props: {
        title: String,
        dataArray: Array,
        selectedValue: Number,
        unit:{
            type: String,
            required: false
        }
    },

    data() {
        return {
            tempSelectedValue: undefined
        }
    },

    mounted() {
        this.tempSelectedValue = this.selectedValue
    },

    watch: {
        selectedValue(value){
            this.tempSelectedValue = value
        },

        tempSelectedValue(value) {
            this.$emit("onSelectedValueChanged", value)
        }
    }
}
</script>

<style lang="scss" scoped>
.pop-view-holography-case-edit-selector {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-title {
        margin-right: 10px;
        margin-left: 32px;
    }

    &-content {
        flex: 1;
        margin-right: 10px;
        /deep/.el-input--suffix .el-input__inner {
            font-size: 16px;
        }

        /deep/.el-select-dropdown__item {
            font-size: 16px;
        }
    }

}
</style>